<template>
  <v-row fill-height align="center" justify="center" no-gutters>
    <v-col cols="6">
      <v-card flat>
        <v-card-text class="d-flex justify-center">
          <v-text-field outlined :placeholder="$i18n.t('placeHolder')" rounded height="55px" class="text-h5"
            hide-details :append-icon="expand ? '' : 'mdi-magnify'"
            :append-outer-icon="expand ? 'mdi-keyboard-off-outline' : ''" @click:append-outer="expand = false"
            @click="expand = true" :value="searchKeyword" v-model="searchKeyword" clearable>
          </v-text-field>
        </v-card-text>
        <v-expand-transition>
          <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
            <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="searchKeyword" class="keyboard">
            </SimpleKeyboard>
          </v-card-text>
        </v-expand-transition>
      </v-card>
    </v-col>
    <v-col cols="12" align="center" @click="expand = false">
      <v-card class="pa-4 containerBorder" width="95%" flat>
        <v-data-table hide-default-footer :items-per-page="-1" :height="viewBoxHeight" :headers="header" :items="list"
          :loading="loading" loading-text="Fetching latest data. Please wait..." fixed-header>
          <template v-slot:item.caseNumber="{ item }">
            <strong>{{ item.caseNumber }}</strong>
          </template>
          <template v-slot:item.partyName="{ item }">
            <div class="my-7" v-if="item.partyName === ''"> - </div>
            <div class="my-7" v-else>
              {{ item.partyName }}
            </div>
          </template>
          <!-- <template v-slot:item.judgeName="{ item }">
            <span v-if="item.judgeName === undefined"> - </span>
            <span v-else>Judge {{ item.judgeName }}</span>
          </template> -->
          <template v-slot:item.hearingLocation="{ item }">
            <v-btn @click="handleCourtLocation(item.hearingLocation)" width="100%" elevation="0" color="primary">
              <v-icon left>mdi-gesture-tap</v-icon>
              {{ item.hearingLocation | trimLength }}
            </v-btn>
          </template>
          <template v-slot:item.hearingTime="{ item }">
            <span>{{ item.hearingTime | dateAndTime }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SimpleKeyboard from "@/components/SimpleKeyboard";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "hearingList",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      expand: false,
      searchKeyword: null,
      viewBoxHeight: 600,
      loading: true,
      header: [
        {
          text: "Case Number",
          align: "center",
          value: "caseNumber",
          width: "200",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: "Parties Name",
          align: "start",
          value: "partyName",
          width: "750",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: "Court Room Location",
          align: "center",
          value: "hearingLocation",
          width: "300",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: "Date/Time",
          align: "center",
          value: "hearingTime",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["hearingInfo", "getKioskProfile", "getSessionId"]),
    list() {
      if (this.searchKeyword === "" || this.searchKeyword === null) {
        console.log('Hearing Data: ', this.hearingInfo)
        return this.hearingInfo;
      } else {
        let temp = [];
        // search by  party name or casenumber
        this.hearingInfo.forEach(caseInfo => {
          if (caseInfo.caseNumber.toLowerCase().includes(this.searchKeyword.toLowerCase()) || caseInfo.partyName.toLowerCase().includes(this.searchKeyword.toLowerCase())) {
            temp.push(caseInfo)
          }
        })
        return temp;
      }
    },
  },
  watch: {
    expand(n, o) {
      if (n) {
        this.viewBoxHeight = 370;
      } else {
        this.viewBoxHeight = 600;
        //user session logging
        const date = new Date()
        this.$store.dispatch('addUserSessionToStrapi', {
          applicationId: this.getKioskProfile.data().arsKioskId,
          actiontype: 'Touch',
          sessionId: this.getSessionId,
          sessionStartTime: date.toISOString(),
          sessionEndTime: null,
          sessionPayload: {
            module: "Today's Hearing",
            action: `Search keyword - " ${this.searchKeyword} "`,
            response: `Found ${this.list.length} records.`,
            timeStamp: date.toISOString()
          }
        })
      }
    },
  },
  methods: {
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      console.log("button", button);
    },
    onInputChange(input) {
      this.searchKeyword = input.target.value;
    },
    handleCourtLocation(location) {
      //this.$store.dispatch("requestQnA", location);
      console.log('Location at courtroom location: ', location)
      this.$i18n.t("maps").forEach(mapper => {
        if (mapper.mapName.toLowerCase().trim() === location.toLowerCase().trim()) {
          console.log('Clicked Map')
          this.$store.dispatch('openImageViewer', {
            name: mapper.mapName,
            url: mapper.mapImage
          })

          this.$store.dispatch('avatarSpeak', mapper.speech);
        }
      })
      //user session logging
      const date = new Date()
      this.$store.dispatch('addUserSessionToStrapi', {
        applicationId: this.getKioskProfile.data().arsKioskId,
        actiontype: 'Touch',
        sessionId: this.getSessionId,
        sessionStartTime: date.toISOString(),
        sessionEndTime: null,
        sessionPayload: {
          module: "Today's Hearing",
          action: "Show Map",
          response: location,
          timeStamp: date.toISOString()
        }
      })
    },
    formatTime(inputTime) {

    }
  },
  filters: {
    dateAndTime(val) {
      if (!val) {
        return "-";
      }

      return 'Today - ' + moment(val, ['hmmA', 'h:mmA']).format('h:mm A')
      //return 'Today - ' + parsedTime.toLocaleString('en-US', options);
    },
    trimLength(val) {
      console.log('Value at trim length: ', val)
      if (val === null || val === undefined) return val;
      if (val.length <= 30) {
        return val;
      }
      return `${val.substring(0, 23)}...`;
    },
  },
  mounted() {
    this.$store.dispatch("fetchingHearingInfo").then(response => {
      if (response) {
        this.loading = false
      }

    });
  }
};
</script>

<style>
.hg-theme-default {
  background-color: #bcbcbc !important;
  font-weight: bolder !important;
  font-size: x-large !important;
}
</style>
<i18n>
  {
    "en": {
        "placeHolder": "Tap to search by Full Name or Case Number"
      },
    "es":{
        "placeHolder": "Toque para buscar por nombre completo o número de caso"
      }
  }
</i18n>